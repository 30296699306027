import { ChartSoundSource } from "../../../../__generated__/gql/graphql"
import { useArea } from "../../contexts/AreaContext";
import { useAreaLocalCoords } from "../../hooks/useAreaLocalCoords";


export type SoundSourceMeshProps = {
  source: ChartSoundSource;
}

export default function SoundSourceMesh({ source }: SoundSourceMeshProps) {
  const { area } = useArea();

  // FIXME move origin 
  const { x, y } = useAreaLocalCoords(
    source.origin.globalLongitude,
    source.origin.globalLatitude,
  );
  const radiusOffset = source.shape.radius * area.localToRealRatio;

  return (
    <>
      <mesh position={[x + radiusOffset, 0, y + radiusOffset]}>
        <sphereGeometry args={[1]} />
        <meshBasicMaterial color={'red'} />
      </mesh>
      <mesh position={[x + radiusOffset, 0, y + radiusOffset]}>
        <sphereGeometry args={[source.shape.radius * area.localToRealRatio]} />
        <meshBasicMaterial wireframe color={'red'} />
      </mesh>
    </>
  )
}